.channel,
.role,
.user,
.team-report,
.project-management,
.settings,
.available-projects,
.my-projects,
.my-report,
.my-revisions,
.subscription,
.upgrade,
.chat {
  // padding: 5px 20px;
  padding: 8px 30px;
}
// .project-management {
//   overflow-x: auto;
// }

.wrapper {
  overflow-x: auto;
  padding: 2px;
}

.modal-form-main {
  .bottom-footer {
    position: sticky;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.07);
  }
}

.subscription {
  .wrapper {
    background-color: #fff;
    width: 100%;
    min-height: 80vh;
    padding: 30px;
    border: 1px solid #e9ebf0;

    .plan {
      font-size: 12px;
      background-color: $color-brand;
      color: #fff;
      border-radius: 50px;
      padding: 5px;
      width: 150px;
      display: flex;
      justify-content: center;
      font-weight: 600;
    }

    .dollar {
      font-size: 20px;
      color: $color-grey-0;
      font-weight: 600;
    }

    .price {
      font-size: 30px;
      color: $color-grey-0;
      font-weight: 600;
    }

    .progress-bar {
      background-color: $color-brand;
    }
    // .table td{
    //   width:20%;
    // }
  }
}

.upgrade {
  .wrapper {
    background-color: #fff;
    width: 100%;
    min-height: 80vh;
    padding: 30px;
    border: 1px solid #e9ebf0;
  }

  .form-control {
    padding-left: 10px;
  }

  .delete-btn {
    height: 40px;
    width: 100%;
  }

  .react-select__control {
    height: 40px;
    border-radius: 10px;
  }
}

.chat {
  .chat-container {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    min-height: 500px;
    max-height: 500px;
    overflow-y: auto;
    .send-comment {
      .comment-input {
        height: 30px;
        width: 100%;
        border: none;
        outline: none;
        font-size: 14px;
        color: $color-grey-0;
        // background-image: url("../svg/comment.svg");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        border-right: 1px solid $color-grey-0;
      }
      ::file-selector-button{
        display: none;
      }
    }
    .chat-input {
      border-top: 1px solid #b9bec7;
      position: sticky;
      box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.07);
      padding: 10px;
    }
    .read-chat {
      overflow: auto;
    }
    .in-chat {
      background: #f9f9f9;
      border: 1px solid #e9ebf0;
      min-height: 70px;
      border-radius: 8px;
      word-break: break-all;
      width: 100%;
    }
    .client-icon {
      justify-content: center;
      padding: 8px;
      border-radius: 50%;
      border: 1px dashed $color-grey-2;
      width: 40px;
      height: 40px;
      display: flex;
      margin: 0 10px;
    }
    .sm-text {
      font-size: 11px;
      font-weight: 400;
      color: #777;
    }
    #file{
      border-right: 1px solid $color-grey-0;
    }
  }
}
