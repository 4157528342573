.header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px 0 20px;
  height: 49px;
  flex-shrink: 0;
  border-bottom: 1px solid #e9ebf0;
  background-color: $bg-secondary;
  position: sticky;
  top: 0;
  z-index: 999;
}
.header-name {
  text-transform: capitalize;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  color: $color-grey-0;
  border-bottom: 3px solid $color-brand;
  border-radius: 3px;
}
.content {
  width: 100%;
  // overflow: auto;
  // padding: 20px 10px;
  .content-title {
    font-size: 24px;
    // margin-bottom: 10px;
    font-weight: 500;
    color: $color-grey-0;
  }
}
.sidebar {
  display: flex;
  border-right: 1px solid $color-grey-3;
  background: $bg-secondary;
  top: 0;
  left: 0;
  .sidebar-container {
    color: #fff;
    height: 100vh;
    width: 62px;
    transition: all 0.5s;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 999;
    .sidebar-logo {
      margin: 10px 10px 20px 10px;
    }
    .sidebar-links {
      .sidebar-link {
        display: flex;
        padding: 8px;
        margin: 10px;
        gap: 15px;
        transition: all 0.5s;
        border-radius: 4px;
      }
      .sidebar-link:hover {
        background-color: #f0f1f3;
        .sidebar-icon {
          svg {
            fill: #2a2e34;
          }
        }
      }
      .active,
      .active:hover {
        background-color: $color-brand;
        .sidebar-icon {
          svg {
            fill: $bg-secondary;
          }
        }
      }
      .badge-icon {
        width: 25px;
        height: 20px;
        padding: 3px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-brand;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
        margin-top: -10px;
        margin-left: -25px;
      }

      .active .badge-icon {
        display: none;
      }
    }
  }
}
