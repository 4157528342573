.modal {
  padding-left: 0 !important;
  .modal-xl {
    max-width: 1240px;
    border-radius: 20px;
  }
  .modal-lg {
    max-width: 1000px;
    border-radius: 20px;
  }
  .modal-md{
    max-width: 650px;
    border-radius: 20px;
    .modal-header{
      background-color: #fff;
      border-bottom: none;
    }
    .modal-body{
      padding: 40px;
    }
  }
  .modal-content {
    border-radius: 4px;
    background-color: $bg-secondary;
    box-shadow: 0 4px 16px #00000029, 0 16px 48px #00000052;
    border: 1px solid $color-grey-1;
  }
  .modal-header {
    height: 49px;
    background-color: #eff0f3;
    border-bottom: 1px solid #e9ebf0;
    .btn-close {
      transition: transform 0.25s, opacity 0.25s;
      box-shadow: none;
    }
    .btn-close:hover {
      transform: rotate(90deg);
      color: $color-brand;
      opacity: 1;
    }
  }
  .modal-title {
    font-size: 24px;
    font-weight: 500;
    color: $color-grey-0;
  }
  .modal-form-main {
    label {
      font-size: 14px;
      font-weight: 500;
      line-height: 11px;
      display: block;
      color: $color-grey-0;
      padding: 0 0 8px 2px;
    }
    .form-control {
      padding: 10px;
    }
    .form-control:active,
    .form-control:focus,
    .form-control:hover {
      border: 1px solid $color-brand;
    }
    .form-control.is-invalid{
      background-image: none;
    }
    .form-control.is-invalid {
      border: 1px solid $color-red;
    }
    
    .form-control.is-invalid:hover,
    .form-control.is-invalid:active,
    .form-control.is-invalid:focus {
      box-shadow: none;
      border-color: $color-red;
    }
  }
  .modal-body {
    padding: 0;
  }
  .react-select__control{
    // height: 40px;
    //  min-height: 0 !important;
    border-radius: 9px;
    align-content: center !important;
    height: auto;
  }
}


//toast
.toast-container{
  .btn-close{
    display: none;
  }
  .toast{
    border-left: 3px solid $color-brand;
    background-color: $bg-secondary;
    border-radius: 5px;
  }
}
.toast-container.is-success{
  .toast{
    .head{
      background-color: $color-green;
      color: #fff;
      padding: 5px;
    }
  }
}
.toast-container.is-error{
  .toast{
    .head{
      background-color: $color-error;
      color: #fff;
      padding: 5px;
    }
  }
}
.toast-container.start-0{
  margin-left: 16px;
}
.toast-container.bottom-0{
  margin-bottom: 15px;
}


.re-invite{
  .modal-header{
    background-color: #fff;
    border-bottom: none;
  }
}
