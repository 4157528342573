  // .wrapper{
  //   width: 198vw;
  //   overflow: auto;
  .board-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex: 1;
    height: 70vh;
    gap: 34px;
    width: 176vw;
    .column {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      // flex: 1;
      width: 248px;
      .card-header {
        box-shadow: 0 2px 8px #00000014;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.3;
        margin: 0;
        color: $color-grey-0;
        text-transform: uppercase;
        background-color: #fff;
        height: 48px;
        display: flex;
        justify-content: center;
        border-radius: 4px;
        border: none;
        .widget {
          background-color: transparent !important;
          border: 1px solid #e9ebf0;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-weight: 500;
          color: #292d34;
          height: 20px;
          border-radius: 20px;
          padding: 2px 8px;
        }
      }
      .column-content {
        overflow: hidden;
        .project-card {
          margin-bottom: 10px;
          .card {
            padding: 7px 10px 2px;
            border-radius: 5px;
            background: #fff;
            box-shadow: 0 1px 2px 1px #0000000f;
            display: flex;
            font-size: 13px;
            border: 1px solid $color-grey-3;
            .user-icon,
            .channel-icon {
              justify-content: center;
              padding: 4px;
              border-radius: 50%;
              border: 1px dashed $color-grey-2;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
            }
            .resource-icon{
              justify-content: center;
              // padding: 4px;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
            }
            .user-icon:hover{
              border: 1px dashed $color-brand;
              svg{
                fill: $color-brand;
              }
            }
            // .channel-icon {
            //   background-color: $color-brand;
            // }
            // .channel-icon > div,
            // .users-icon {
            //   font-size: 20px;
            //   color: white;
            //   text-transform: uppercase;
            // }
            .dropdown .dropdown-menu,
            .dropstart .dropdown-menu {
              min-width: 230px;
              padding: 5px;
            }
          }
          .card:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          }
        }
        .add-project {
          // .add-btn {
          //   // display: none;
          // }
          .save-btn {
            background: #7b68ee;
            height: 17px;
            padding: 0 6px;
            border-radius: 3px;
            color: #fff;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            font-weight: 500;
          }
          .input-box {
            font-size: 13px;
            font-weight: 400;
            line-height: 1.2;
            color: $color-grey-0;
            width: 100%;
            border: 0;
            background-color: transparent;
            box-shadow: none;
            resize: none;
            padding-right: 5px;
            padding-left: 15px;
            outline: none;
          }
          .inputBox::placeholder {
            font-size: 13px;
            font-weight: 300;
            line-height: 1.2;
            opacity: 0.4;
          }
          .card {
            border: 1px solid $color-brand;
            border-radius: 0;
          }
        }
      }
      .column-content:hover {
        overflow-y: auto;
        .add-btn,
        .inputBox:active,
        .inputBox:focus {
          display: block;
        }
      }
      .column-content::-webkit-scrollbar {
        width: 8px;
      }
      .column-content::-webkit-scrollbar-track {
        background: transparent; /* color of the tracking area */
      }
      .column-content::-webkit-scrollbar-thumb {
        background-color: $color-grey-2; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
        border: $color-grey-2; /* creates padding around scroll thumb */
      }
    }
  }


.drop .dropdown-toggle::before,
.dropdown-toggle::after {
  display: block;
}
.dropdown .dropdown-toggle.show,
.dropstart .dropdown-toggle.show {
  padding: 0;
  background-color: #fff;
}
.dropdown-item {
  white-space: break-spaces;
}

@media screen and (min-width: 1400px) {
  .board-container {
    width: 157vw;
  }
}
@media screen and (min-width: 1600px) {
  .board-container {
    width: 143vw;
  }
}
@media screen and (min-width: 1900px) {
  .board-container {
    width: 120vw;
  }
}

 

