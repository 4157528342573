.dashboard {
  padding: 5px 20px;
  .dashboard-title {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 600;
    color: $color-grey-0;
  }
  .dashboard-text {
    font-size: 16px;
    font-weight: 600;
    color: $color-grey-1;
    font-style: italic;
  }
  .card {
    border-radius: 10px;
    border: 1px solid $color-grey-2;
    box-shadow: 0 1px 3px #0000001c;
    background: #fff;
    .card-body {
      padding: 20px 10px;
      text-align: center;
      p {
        margin: 0;
      }
    }
    .outter-icon{
        padding: 10px;
        border-radius: 50px;
        background-color: $theme-main-color-light;
    }
    .icon {
      padding: 12px;
      border-radius: 50%;
      background-color: $color-brand;
    }
    .count-value {
      font-size: 35px;
      font-weight: 600;
      color: $color-grey-1;
    }
    .count-text {
      font-size: 14px;
      color: $color-brand;
      font-weight: 500;
    }
    hr{
        margin: 0;
    }
  }
}
