//global variables
$font: -apple-system,BlinkMacSystemFont,Segoe UI,roboto,Helvetica Neue,helvetica,arial,sans-serif;
$color-grey-0: #292d34;
$color-grey-1: #7c828d;
$color-grey-2: #b9bec7;
$color-grey-3: #e9ebf0;
$color-grey-4: #fafbfc;
$color-grey-5:#2a2e34;
$color-white: #fff;
$color-black: #000;
$color-pink: #eb3dae;
$color-pink-dark: #de1798;
$color-red: #fd7171;
$color-blue: #5bc5fa;
$color-cyan: #49ccf9;
$color-green: #6bc950;
$color-teal: #5cc0bf;
$color-teal-light: #8afcde;
$color-yellow: #f7ce51;
$color-yellow-dark: #f5cd51;
$color-purple: #7b42d1;
$color-purple-dark: #8041d0;
$color-background: #fafbfc;
$color-brand-facebook: #3b5998;
$color-brand-instagram: #c32aa3;
$color-brand-linkedin: #0077b5;
$color-brand-twitter: #1da1f2;
$color-brand-youtube: red;
$color-success: $color-green;
$color-error: #e04f44;
//bg
$bg-main: $color-grey-4;
$bg-secondary: $color-white;
//theme color
$color-brand: #7b68ee;
$theme-main-color: #7b68ee;
$theme-main-color-dark: #5f48ea;
$theme-main-color-light : #dfdbfb;
//table
$color-table-header:#f0f1f3e6;
//cards
$color-card-1:#d3d3d3;
$color-card-2: #ff7fab;
$color-card-3:#2ecd6f;
$color-card-4:#f9d900;
$color-card-5:#02BCD4;
$color-card-6:#ff7800;
$color-card-7:#bf55ec;
$color-card-8:#e50000;
$color-card-9:#0231E8;
