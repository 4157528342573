.project-form {
  .desc_textarea {
    font-size: 14px;
  }

  textarea {
    padding: 10px;
    font-size: 26px;
    font-weight: 400;
    line-height: 34px;
    color: $color-grey-0;
    flex-grow: 1;
    outline: none;
    text-indent: 0px;
    min-height: 34px;
    max-height: 170px;
    height: 102px;
  }

  .status-icon,
  .client-icon,
  .visible-icon,
  .actions-icon {
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    border: 1px dashed $color-grey-2;
    width: 40px;
    height: 40px;
    display: flex;
    margin: 0 10px;
  }

  .client-icon:hover,
  .client-icon:focus,
  .visible-icon:hover,
  .visible-icon:focus,
  .visible-icon:active .actions-icon:hover {
    border: 1px dashed $color-brand;

    svg {
      fill: $color-brand;
    }
  }

  .status-icon,
  .actions-icon {
    align-items: center;
  }

  .client-icon {
    background-color: $theme-main-color;
    color: #fff;
  }

  .top-header {
    position: sticky;
    display: flex;
  }

  .bottom-footer {
    position: sticky;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.07);
  }

  .bottom-footer-1 {
    border-top: 1px solid $color-grey-2;
    position: sticky;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.07);
  }

  .main-content {
    max-height: calc(94vh - 210px);
    overflow-y: auto;
  }

  .main-content::-webkit-scrollbar {
    display: none;
  }

  .form-control {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0;
  }

  .form-control:active,
  .form-control:focus,
  .form-control:hover {
    border-color: $color-brand;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    cursor: text;
    text-transform: uppercase;
    color: #34343499;
  }

  .stage-btn {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    height: 40px;
  }

  .sm-label {
    color: #34343499;
    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    cursor: text;
    text-transform: uppercase;
  }

  .sm-text {
    font-size: 11px;
    font-weight: 400;
    color: #777;
  }

  .main-content-1 {
    height: calc(94vh - 210px);
    background-color: #fbfbfb;
  }

  .project-link {
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: 19px;
    word-break: break-all;
    overflow: hidden;
    // padding: 6px 24px 6px 7px;
    // line-break: loose;
    // line-height: 1;
    display: flex;
    align-items: center;
  }

  .edit-icon{
    position: relative;
    padding-right: 9px;
    cursor: pointer;
    float: right;
    margin-top: -32px;
    top: 40px;
  }
}

.client-icon {
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  border: 1px dashed $color-grey-2;
  width: 40px;
  height: 40px;
  display: flex;
  margin: 0 10px;
}

.client-icon:hover,
.client-icon:focus {
  border: 1px dashed $color-brand;

  svg {
    fill: $color-brand;
  }
}

.client-icon {
  background-color: $theme-main-color;
  color: #fff;
}

.send-comment {
  .comment-input {
    height: 30px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    color: $color-grey-0;
    // background-image: url("../svg/comment.svg");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    line-height: 15px;
    min-height: 34px;
    max-height: 50px;
  }
}

.read-comment {
  max-height: calc(94vh - 210px);
  overflow-y: auto;

  .comment-box {
    background: #fff;
    border: 1px solid #e9ebf0;
    min-height: 70px;
    border-radius: 8px;
    word-break: break-all;
  }

  .comment-text {
    font-size: 14px;
    color: $color-grey-0;
  }

  // .comment-box {
  //   .comment-actions {
  //     display: none;
  //   }
  // }
  // .comment-box:hover .comment-actions {
  //   display: block;
  // }
  // .comment-box:hover .date {
  //   display: none;
  // }
  .sm-label {
    color: #34343499;
    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    cursor: text;
    text-transform: uppercase;
  }

  .sm-text {
    font-size: 11px;
    font-weight: 400;
    color: #777;
  }
}

.read-comment::-webkit-scrollbar {
  display: none;
}