.tabs-section{
    .nav-tabs{
        .nav-link.active{
            color: $color-grey-0;
            background-color: $bg-main;
            border-bottom: 3px solid $theme-main-color;
            border-radius: 3px;
        }
        .nav-link{
            color: $color-grey-1;
            border: none;
            margin-bottom: 0;
            font-size: 14px;
        }
        .nav-link:hover{
            color: $color-grey-0;
        }
    }
}