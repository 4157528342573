@import "~bootstrap/scss/bootstrap";
@import "./Assets/Styles/Theme/variables.scss";
@import "./Assets/Styles/login.scss";
@import "./Assets/Styles/Theme/input.scss";
@import "./Assets/Styles/Theme/button.scss";
@import "./Assets/Styles/sidebar.scss";
@import "./Assets/Styles/dashboard.scss";
@import "./Assets/Styles/content.scss";
@import "./Assets/Styles/Theme/tabs.scss";
@import "./Assets/Styles/Theme/modal.scss";
@import "./Assets/Styles/kanban.scss";
@import "./Assets/Styles/projectForm.scss";
@import "./Assets/Styles/Theme/loader.scss";

body {
  margin: 0;
  background-color: $bg-main;
  font-family: $font;
  width: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: $theme-main-color;
}

a:hover {
  color: $theme-main-color;
  text-decoration-line: underline;
}

//table
table {
  width: 100%;
}
.table {
  border: 1px solid #e9ebf0;
  tr {
    border-bottom: #f7f8f9e6;
    border: 1px solid #e9ebf0;
  }
  tbody {
    tr:hover {
      background: #f7f8f9e6;
    }
  }
  th {
    background-color: $color-table-header;
    color: $color-grey-0;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    height: 28px;
  }
  td {
    background-color: #fff;
    min-height: 40px;
    font-size: 14px;
    color: $color-grey-0;
    vertical-align: middle;
  }
}
.table-responsive{
  min-height: 205px;
}

//vertical line
.vertical-line {
  border-left: 1px solid $color-grey-2;
}
.horizontal-bottom-line {
  border-bottom: 1px solid $color-grey-2;
  width: 100%;
}
.horizontal-top-line {
  border-top: 1px solid $color-grey-2;
  width: 100%;
}

//pagination
 .pagination .page-item .page-link:hover {
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  border-radius: .125rem;
  background-color: #eee;
}
 .pagination.pg-blue .page-item.active .page-link:hover {
  background-color: $color-brand;
}
 .pagination .page-item.active .page-link {
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  border-radius: .125rem;
  background-color: $color-brand;
  color: #fff;
}
 .pagination .page-item .page-link {
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  outline: 0;
  border: 0;
  background-color: transparent;
  font-size: .9rem;
  color: black;
}
 .pagination .page-item.active .page-link{
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
 .page-link{
border:none!important;
}
 .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0;
}

.asterisk{
  color: $color-error;
  font-size: 14px;
}
