//login-header
.login-header {
  margin: 2rem;

  .brand_name {
    margin-top: 0.4rem;
    font-weight: bold;
  }
}

//login-layout
.login-layout {
  .login-main-bg {
    position: absolute;
    height: calc(100% - 50vh);
    width: 100%;
    top: 50vh;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    background-image: url("../svg/wave.svg");
    background-size: cover;
  }
}

//login-main
.login-main {
  .login-main-container {
    width: 480px;
    background: #fff;
    box-shadow: 0 24px 64px #26214a1a;
    border-radius: 12px;
    padding: 30px 60px;
    position: relative;
  }
}

//login-form
.login-form {
  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 46px;
    color: $color-grey-0;
    text-align: center;
    margin-bottom: 20px;
  }

  .sign-in-link {
    font-size: 12px;
    text-align: center;
  }

  .login-form-main {
    label {
      font-size: 11px;
      font-weight: 500;
      line-height: 11px;
      display: block;
      color: $color-grey-0;
      padding: 0 0 8px 2px;
    }

    .login-main-row {
      margin-bottom: 26px;
    }

    .form-control {
      padding-left: 10px;
      padding-right: 110px;
    }

    .form-control.form-control-email {
      padding-left: calc(1.5em + 0.75rem);
      background-image: url("../svg/inactiveIcons/envelope-alt.svg");
      background-repeat: no-repeat;
      background-position: left calc(0.375em + 0.1875rem) center;
      background-size: 19px;
      padding-right: 10px;
    }

    .form-control.form-control-email.is-invalid {
      background-image: url("../svg/activeIcons/envelope-alt.svg");
    }

    .form-control.form-control-pass {
      padding-left: calc(1.5em + 0.75rem);
      background-image: url("../svg/inactiveIcons/lock-alt.svg");
      background-repeat: no-repeat;
      background-position: left calc(0.375em + 0.1875rem) center;
      background-size: 19px;
    }

    .form-control.form-control-pass.is-invalid {
      background-image: url("../svg/activeIcons/lock-alt.svg");
    }

    .forgot-password {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 0;
      cursor: pointer;
      position: relative;
      top: -20px;
      flex-direction: row-reverse;

      .forgot-password-link {
        font-size: 12px;
        font-weight: 500;
        margin-right: 10px;
      }
    }
  }
}

// media queries
@media only screen and (max-width: 768px) {
  .login-main {
    .login-main-container {
      width: 345px;
      margin: 20px 10px 50px;
      padding: 20px;
    }
  }
}
