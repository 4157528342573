.form-control {
  transition: border-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  width: 100%;
  border: 1px solid #d6d9de;
  box-sizing: border-box;
  border-radius: 9px;
  padding: 0 20px 0 0;
  height: 40px;
}

.form-control:active,
.form-control:hover,
.form-control:focus {
  border-color: #adb3bd;
  box-shadow: none;
}

.form-control.is-invalid {
  border: 1px solid $color-red;
}

.form-control.is-invalid:hover,
.form-control.is-invalid:active,
.form-control.is-invalid:focus {
  box-shadow: none;
  border-color: $color-red;
}

.invalid-feedback {
  font-size: 12px;
  font-weight: 500;
  color: $color-error;
  margin: 5px 0 0;
}

.searchBox {
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  line-height: 1.2;
  color: $color-grey-0;
  width: 100%;
  background: #fff;
  border: 1px solid #e8eaed;
  box-shadow: none;
  resize: none;
  padding: 0 6px 0 30px;
  outline: none;
  border-radius: 6px;
  background-image: url("../../svg/search.svg");
  background-repeat: no-repeat;
  background-position: left calc(0.375em + 0.1875rem) center;
  background-size: 19px;
}

.searchBox::placeholder {
  color: $color-grey-1;
}

.react-select__control {
  height: 32px;
  min-height: 32px !important;
  border-color: #d6d9de !important;
  box-shadow: none !important;
  font-size: 14px;
  align-content: center !important;

  .react-select__indicator-separator {
    background-color: #fff;
  }

  .react-select__dropdown-indicator {
    svg:hover {
      fill: $color-brand;
      stroke: $color-brand;
    }
  }

  .react-select__multi-value {
    background-color: $color-brand;
    color: #fff;

    .react-select__multi-value__label {
      color: #fff;
    }

    .react-select__multi-value__remove:hover {
      background-color: $theme-main-color-dark;
      color: #fff;
    }
  }
}

.react-select__menu {
  font-size: 14px;
  color: $color-grey-0;
  margin-top: 10px;
  padding: 10px 0;
}

.react-select__menu-list {
  padding: 15px 15px 15px 15px;
}

.react-select__option {
  border-radius: 5px;
}

.react-select__option:hover,
.react-select__option--is-focused {
  background-color: $color-grey-3;
}

.react-select__option--is-selected {
  background-color: $color-brand;
  color: #fff;
}

.react-select__control--menu-is-open {
  .react-select__dropdown-indicator {
    svg {
      transform: rotate(180deg);
      fill: $color-brand;
      stroke: $color-brand;
    }
  }
}

.react-select-container.is-invalid {
  .react-select__control {
    border-color: $color-red !important;
  }
}

//check box
.form-check-input {
  width: 1.2em;
  height: 1.2em;
}

.form-check-input:checked {
  background-color: $color-brand;
  border: $color-brand;
}

//date
.date.form-control {
  padding: 10px;
  height: 32px;
  border-radius: 3px;
  background-image: url("../../svg/calender.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: 19px;
}

input::-webkit-datetime-edit-fields-wrapper {
  color: $color-grey-0;
}

input::-webkit-calendar-picker-indicator {
  background-image: none;
}

.select-form-control {
  font-size: 14px;
  color: $color-grey-1;

  select {
    border: 2px solid $color-grey-1;
    outline: none;
    box-shadow: none;
    background-color: #fff;
    border-radius: 10px;
  }

  select:hover,
  select:active,
  select:focus {
    border: 2px solid $color-brand;
  }
}

.user {
  .react-select__menu {
    text-transform: capitalize !important;
  }
}

.user-form{
  .react-select__menu {
    text-transform: capitalize !important;
  }
}

// .react-select-user__menu{
//   text-transform: capitalize !important;
// }