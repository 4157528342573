.btn:hover,
.btn:focus,
.btn:active {
  color: transparent;
  background-color: transparent;
  border: none;
  outline: none;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: 0;
  border-radius: 9px;
  width: 100%;
  margin-bottom: 22px;
  box-shadow: 0 10px 25px #7b68ee80;
  background-color: $theme-main-color;
  font-size: 14px;
  font-weight: 800;
  line-height: 1;
  color: #fff;
}

.login-button:hover {
  background-color: $theme-main-color-dark;
}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 3px 10px -3px $theme-main-color;
  background-color: $theme-main-color;
  font-size: 14px;
  line-height: 0.5;
  color: #fff;
  padding: 0 5px;
}

.add-button:hover {
  background-color: $theme-main-color-dark;
  transform: scale(1.03);
}

.apply-button {
  height: 32px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 3px 10px -3px $theme-main-color;
  background-color: $theme-main-color;
  font-size: 14px;
  line-height: 0.5;
  color: #fff;
  padding: 10px;
}

.apply-button:hover {
  background-color: $theme-main-color-dark;
}

.cancel-button {
  height: 32px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 3px 10px -3px $color-grey-2;
  background-color: $color-grey-2;
  font-size: 14px;
  line-height: 0.5;
  color: #fff;
  padding: 10px;
}

//dropdowns
.dropdown,
.dropstart {
  .dropdown-toggle {
    padding: 2px;
  }
  .btn-primary,
  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary:hover {
    color: transparent;
    background-color: transparent;
    border: none;
  }
  .dropdown-toggle::after {
    border: none;
  }
  .dropdown-toggle::before {
    border: none;
    margin: 0;
  }
  .dropdown-toggle.show {
    background-color: $color-grey-3;
    padding: 5px 8px;
  }
  .dropdown-item:focus,
  .dropdown-item:active {
    background-color: $color-grey-3;
  }
  .dropdown-menu {
    box-shadow: 0 1px 10px #00000040;
    background: #fff;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    .dropdown-item {
      border-radius: 5px;
      color: #292d34;
      text-decoration: none;
      padding: 5px;
    }
  }
}

//project button
.project-button {
  background: transparent;
  outline: none;
  border: none;
  color: $color-grey-0;
  font-size: 14px;
  padding: 8px 8px;
  border-radius: 5px;
}
.project-button:hover {
  background-color: $color-table-header;
}

.delete-modal {
  .footer {
    font-size: 18px;
    line-height: 63px;
    margin: 50px 0 0;
    .cancel-btn {
      width: calc(50% - 10px);
      background: #fff;
      color: #aeacb0;
      border: 1px solid #d8d8d8;
      font-weight: 600;
      border-radius: 5px;
    }
    .delete-btn {
      width: calc(50% - 10px);
      background: $color-brand;
      color: #fff;
      border: 1px solid $color-brand;
      font-weight: 600;
      border-radius: 5px;
    }
    .delete-btn:hover {
      background: $theme-main-color-dark;
    }
  }
}

.re-invite {
  .footer {
    font-size: 18px;
    line-height: 40px;
    margin: 50px 0 0;
  }
  .cancel-btn {
    width: calc(50% - 10px);
    background: #fff;
    color: #aeacb0;
    border: 1px solid #d8d8d8;
    font-weight: 600;
    border-radius: 5px;
  }
  .delete-btn {
    width: calc(50% - 10px);
    background: $color-brand;
    color: #fff;
    border: 1px solid $color-brand;
    font-weight: 600;
    border-radius: 5px;
  }
  .delete-btn:hover {
    background: $theme-main-color-dark;
  }
}

.icon-btn {
  border: none;
  background-color: transparent;
}

.icon-btn:hover,
.icon-btn:active,
.icon-btn:focus {
  svg {
    fill: $color-brand;
  }
}
